@font-face {
  font-family: 'Roboto-Light';
  src: local('Roboto-Light'), url('./fonts/rotobo/Roboto-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'), url('./fonts/rotobo/Roboto-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url('./fonts/rotobo/Roboto-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url('./fonts/rotobo/Roboto-Regular.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Opensans-Bold';
  src: local('Opensans-Bold'), url('./fonts/opensans/OpenSans-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Opensans-Medium';
  src: local('Opensans-Medium'), url('./fonts/opensans/OpenSans-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Opensans-Regular';
  src: local('Opensans-Regular'), url('./fonts/opensans/OpenSans-Regular.ttf') format('truetype');
  font-display: swap;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-transform-wrapper {
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.transform-component-module_wrapper__1_Fgj {
  width: 100% !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow:  0 0 0px 100px #252525 inset !important;
    -webkit-box-shadow: 0 0 0px 100px #252525 inset !important;
    -webkit-text-fill-color: white !important;
}
@media (min-width: 2566px) and (max-width: 3000px){
  .highcharts-container {
    height: 460px !important;
  }
  .highcharts-root{
    height: 640px !important;
  }
}